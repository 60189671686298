import { createApp } from '../../main-factory';
import routes from './routes';  // 电商路由
import store from '../../store/common';    // 电商状态管理

const app = createApp({
    routes,
    store,
    titleSuffix: '电商服务工具平台',
    appName: 'ecommerce',
    loginPath: '/login',
    basePath: '/',  // 应用路径
    App: require('./App.vue').default,  // 根组件
});
app.$mount('#app');  // 挂载到 #app
